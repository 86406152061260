<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="">
        <div v-if="mode=='edit'" class="mb-3">
          <label class='txt-right'>Thuộc nhóm quyền</label>
          <Textarea
            v-model="user.role"
            :autoResize="true"
            rows="1"
            class="text-box input-text-box disabled"
            disabled
          />
        </div>
        <div v-if="mode=='edit'" class="">
          <label class="txt-right">Username</label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="user.username"
            :autoResize="true"
            rows="1"
            maxlength="50"
            class="text-box input-text-box disabled"
            disabled
          />
          <!-- </div> -->
        </div>
        <div v-else class="">
          <label class="txt-right required">Username</label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="user.username"
            :autoResize="true"
            rows="1"
            maxlength="50"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="username" />
          <!-- </div> -->
        </div>
        <div class="">
          <label class="txt-right required mt-3">Tên</label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="user.fullname"
            :autoResize="true"
            rows="1"
            maxlength="255"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="fullname" />
          <!-- </div> -->
        </div>
        <div class="">
          <label class="txt-right required mt-3">Email</label>
          <!-- <div class="p-col"> -->
          <InputText
            v-model="user.email"
            type="text"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="email" />
          <!-- </div> -->
        </div>
        <div class="">
          <label class="txt-right mt-3">Số điện thoại</label>
          <!-- <div class="p-col"> -->
          <InputText
            v-model="user.phone"
            id="phone"
            type="text"
            maxlength="20"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <!-- </div> -->
        </div>
        <div class="">
          <label class="txt-right required mt-3">Công ty</label>
          <MultiSelect
          class="text-box"
          v-model="user.company"
          :options="company_list"
          optionLabel="name"
          optionValue="id"
          placeholder="Chọn"
          >
            <template #value="slotProps">
              <span v-for="(option, index) of slotProps.value" :key="option.code">{{ getCompanyName(option) }}<span v-if="index !== slotProps.value.length - 1">; </span>
              </span>
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                  Chọn
              </template>
            </template>
          </MultiSelect>
          <input class="input_tmp_validator" id="company" />
        </div>
        <div>
          <label class="txt-right required mt-3">Nhóm vận hành</label>
<!--          <Dropdown
            v-model="user.department_id"
            :options="department_list"
            optionLabel="name"
            optionValue="id"
            placeholder="Chọn"
            class="text-box"
          /> -->

          <MultiSelect class="text-box" id="group_channel_company" v-model="user.department_id" :options="department_list" optionLabel="name" optionValue="id"  placeholder="Chọn " />
          <span slot="no-options" @click="$refs.select.open = false">
              </span>

          <input class="input_tmp_validator" id="department" />
        </div>
        <div v-if="mode == 'add'">
          <label class="txt-right required mt-3">Mật khẩu</label>
          <Password
            required
            v-model="user.password"
            class="text-box"
            toggleMask
            maxlength="50"
            placeholder="Nhập"
          >
            <template #footer>
              <p class="mt-2">Mật khẩu phải chứa</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>Tối thiểu 1 ký tự số</li>
                <li>Tối thiểu 1 ký tự in hoa</li>
                <li>Tối thiểu 1 ký tự đặc biệt ~!@#$%^&*()-+={}[]/;’”,.</li>
                <li>Tối thiểu 8 ký tự</li>
              </ul>
            </template>
          </Password>
          <input type="password" class="input_tmp_validator" id="password" />
        </div>
        <div v-else>
          <label class="txt-right mt-3">Mật khẩu</label>
          <Password
            v-model="user.password"
            class="text-box"
            toggleMask
            placeholder="Nhập"
          >
            <template #footer>
              <p class="mt-2">Mật khẩu phải chứa</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>Tối thiểu 1 ký tự số</li>
                <li>Tối thiểu 1 ký tự in hoa</li>
                <li>Tối thiểu 1 ký tự đặc biệt ~!@#$%^&*()-+={}[]/;’”,.</li>
                <li>Tối thiểu 8 ký tự</li>
              </ul>
            </template>
          </Password>
          <input type="password" class="input_tmp_validator" id="password" />
        </div>
        <div class="flex justify-content-center pt-3">
          <Checkbox v-model="user.active" :binary="true" />Hoạt động
        </div>
        <!-- </div> -->
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning return-style-button"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
// import { HotModuleReplacementPlugin } from "webpack";
import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  data() {
    return {
      user: {
        username: "",
        fullname: "",
        email: "",
        phone: "",
        company: [],
        department_id: [],
        password: "",
        active: true,
        role: "",
      },
      company_list: [],
      department_list: [],
      code: "",
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role: [],
      saving: false,
      savedEmail: "",
      addressData: {
        street_number: "251",
        route: "Quang Trung",
        administrative_area_level_1: "Thành phố Hồ Chí Minh",
        country: "Việt Nam",
        latitude: 10.8286588,
        longitude: 106.6732573,
      },
      placeResultData: {
        address_components: [
          { long_name: "251", short_name: "251", types: ["street_number"] },
          {
            long_name: "Quang Trung",
            short_name: "Quang Trung",
            types: ["route"],
          },
          {
            long_name: "Gò Vấp",
            short_name: "Gò Vấp",
            types: ["administrative_area_level_2", "political"],
          },
          {
            long_name: "Thành phố Hồ Chí Minh",
            short_name: "Thành phố Hồ Chí Minh",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "Việt Nam",
            short_name: "VN",
            types: ["country", "political"],
          },
        ],
        adr_address:
          '<span class="street-address">251 Quang Trung</span>, <span class="extended-address">Phường 10</span>, <span class="locality">Gò Vấp</span>, <span class="region">Thành phố Hồ Chí Minh</span>, <span class="country-name">Việt Nam</span>',
        formatted_address:
          "251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
        geometry: {
          location: { lat: 10.8286588, lng: 106.6732573 },
          viewport: {
            south: 10.8273459697085,
            west: 106.6719226697085,
            north: 10.8300439302915,
            east: 106.6746206302915,
          },
        },

        icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
        name: "251 Quang Trung",
        place_id: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        plus_code: {
          compound_code: "RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
          global_code: "7P28RMHF+F8",
        },
        reference: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        types: ["street_address"],
        url: "https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a",
        utc_offset: 420,
        html_attributions: [],
        utc_offset_minutes: 420,
      },
    };
  },
  async mounted() {
    // var that = this;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      // await this.getUserName();
      // this.user = this.$commonFuction.convertJsonObject(this.model);
      // this.user.company = this.model.users_companies;
      this.user.active = this.model.active;
      this.user.fullname = this.model.fullname;
      this.user.phone = this.model.phone;
      this.user.email = this.model.email;
      this.user.active = this.model.active;
      var list_companies = [];
      for (let i = 0; i < this.model.users_companies.length; i++){
        list_companies.push(this.model.users_companies[i].company_id);
      }
      var list_roles = "";
      for (let i = 0; i < this.model.users_group.length; i++){
        if (i){
          list_roles += ("; " + this.model.users_group[i].group.name)
        }
        else list_roles += (this.model.users_group[i].group.name)
      }
      this.user.role = list_roles;
      this.savedEmail = this.model.email;
      this.user.company = list_companies;
      //this.user.department_id = this.model.department.id;
      var list_department = [];
      if (!this.$commonFuction.isEmpty(this.model.department_users)) {
        for (let t = 0; t < this.model.department_users.length; t++) {
          list_department.push(this.model.department_users[t]["department_id"]);
        }
      }
      this.user.department_id = list_department;
      this.user.username = this.model.accounts[0].account;
    }
    console.log("AM VBEE", this.model);
    
    this.listGroupRole().then((res) => {
      console.log("resres", res);
      this.list_group_role = res;
    });
    await this.getCompanyList();
    await this.getDepartmentList();
    // console.log("AAAAAAAAAAAA");
  },
  methods: {
    getCompanyName(id){
    for (let i = 0; i < this.company_list.length; i++){
      if (this.company_list[i].id == id) return this.company_list[i].name;
    }
    },
    async getUserName(){
      var gqlQueryList = DataServices.getList("accounts");
      var resData = await this.$apollo.mutate({
        mutation: gqlQueryList,
        variables: {
          where: { uid: { _eq: this.model.id } },
        },
      });
      this.user.username=resData.data.accounts[0].account;
    },
    async getCompanyList() {
      var gqLQueryList = DataServices.getList("company");
      console.log("gqL", gqLQueryList);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: { created_at: "desc" },
        },
      });
      var listData = resData.data["company"];
      this.company_list = listData;
    },
    async getDepartmentList() {
      var gqLQueryList = DataServices.getList("department");
      console.log("gqL", gqLQueryList);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: { ordinal: "asc" },
        },
      });
      var listData = resData.data["department"];
      this.department_list = listData;
    },
    async listGroupRole() {
      var where = {
        active: { _eq: true },
        deleted: { _eq: false },
      };
      var order_by = { id: "desc" };
      var listData = await this.$CoreService.getListData(
        "group",
        where,
        null,
        null,
        order_by
      );
      return listData;
    },
    backToList() {
      this.$emit("back");
    },
    async validatePassword() {
      console.log("HI");
      if (this.user.password.length < 8) {
        return "Mật khẩu phải có ít nhất 8 ký tự!";
      }
      if (this.user.password.length > 50) {
        return "Mật khẩu không được quá 50 ký tự!";
      }
      if (!/[A-Z]/.test(this.user.password)) {
        console.log("Where a u 2");
        return "Mật khẩu phải chứa ít nhất 1 ký tự in hoa!";
      }
      if (!/[0-9]/.test(this.user.password)) {
        return "Mật khẩu phải chứa ít nhất 1 ký tự số!";
      }
      var re = /^(?=.*\d)(?=.*[!@#$%^&*])/;
      if (!re.test(this.user.password)) {
        console.log("Where a u");
        return "Mật khẩu phải chứa ít nhất 1 ký tự đặc biệt!";
      }
      if (!((/[A-Z]/.test(this.user.password)) || (/[0-9]/.test(this.user.password)) || (re.test(this.user.password)) || (/[a-z]/.test(this.user.password))))
        return "Mật khẩu không hợp lệ!"
      console.log("HIHI");
      return "";
    },
    validateEmail() {
      var re = /\S+@\S+\.\S+/;
      console.log("SHIBABABABABA", re.test(this.user.email));
      return re.test(this.user.email);
    },
    async checkExistEmail() {
      var query_email = gql`
      query QueryEmail {
        users(where: {email: {_eq: "${this.user.email}"}}) {
          id
        }
      }`;
      var res_data = await this.$apollo.query({
        query: query_email,
      });
      console.log("EMAILLLL", res_data.data);
      if (res_data.data.users.length == 0) return true;
      return false;
    },
    async validateUsername() {
      var query_username = gql`
      query QueryUsername {
        accounts(where: {account: {_eq: "${this.user.username}"}}) {
          id
        }
      }`;
      var res_data = await this.$apollo.query({
        query: query_username,
      });
      console.log("ACCOUNTTTTT", res_data.data);
      if (res_data.data.accounts.length == 0) return true;
      return false;
    },
    validatePhone(){
      for (let i = 0; i < this.user.phone.length; i++){
        if (!((this.user.phone[i] >= '0' &&  this.user.phone[i] <= '9') || (i == 0 && this.user.phone[i] == '+'))){
          return false;
        }
      }
      if (this.user.phone == "+") return false;
      return true;
    },
    checkSpecial(){
      const constraint = "~!@#$%^&*()-+={}[]/;’”,.";
      for (let i = 0; i < this.user.username.length; i++){
        if (constraint.includes(this.user.username[i])){
          return true;
        }
      }
      return false;
    },
    async validateData() {
      this.validationErrors = {};
      if (this.mode == "add"){
      let username_add = document.getElementById("username");
      const username_valid = await this.validateUsername();

      if (
        this.user.username == undefined ||
        this.user.username == null ||
        this.user.username == ""
      ) {
        username_add.setCustomValidity("Tên đăng nhập không được để trống!");
      } else if (this.checkSpecial()){
        username_add.setCustomValidity("Tên đăng nhập không được chứa ký tự đặc biệt!");
      } else if (this.user.username.length > 255) {
        username_add.setCustomValidity(
          "Tên đăng nhập không được quá 255 ký tự!"
        );
      } else if (!username_valid) {
        username_add.setCustomValidity("Tên đăng nhập đã tồn tại!");
      } else {
        username_add.setCustomValidity("");
      }
    }
      let name_add = document.getElementById("fullname");
      if (
        this.user.fullname == undefined ||
        this.user.fullname == null ||
        this.user.fullname == ""
      ) {
        name_add.setCustomValidity("Tên không được để trống!");
      } else if (this.user.fullname.length > 255) {
        name_add.setCustomValidity("Tên không được quá 255 ký tự!");
      } else {
        name_add.setCustomValidity("");
      }

      let email_add = document.getElementById("email");
      if (this.mode == 'edit' && this.user.email == this.savedEmail){
        console.log('com ga');
        email_add.setCustomValidity("");
      }
      else {
      const email_exist = await this.checkExistEmail();
      console.log("shibachao2", email_exist);
      if ( this.$commonFuction.isEmpty(this.user.email)) {
        email_add.setCustomValidity("Email không được để trống!");
      } else if (!this.validateEmail()){
        email_add.setCustomValidity("Email không đúng định dạng!");
      } else if (!email_exist) {
        email_add.setCustomValidity("Email đã tồn tại");
      } else {
        email_add.setCustomValidity("");
      }
    }
      let phone_add = document.getElementById("phone");
      if (this.user.phone.length > 20) {
        phone_add.setCustomValidity("Số điện thoại không được quá 20 ký tự!");
      } else if (!this.validatePhone()) {
        phone_add.setCustomValidity("Số điện thoại không hợp lệ");
      } else {
        phone_add.setCustomValidity("");
      }

      let company_add = document.getElementById("company");
      if (
        this.user.company == undefined ||
        this.user.company == null ||
        this.user.company == ""
      ) {
        company_add.setCustomValidity("Công ty không được để trống!");
      } else {
        company_add.setCustomValidity("");
      }

      let department_add = document.getElementById("department");
      if (
        this.user.department_id == undefined ||
        this.user.department_id == null ||
        this.user.department_id == ""
      ) {
        department_add.setCustomValidity("Bộ phận không được để trống!");
      } else {
        department_add.setCustomValidity("");
      }
      let password_add = document.getElementById("password");
      if ((this.user.password == "" || this.user.password == undefined || this.user.password == null) && (this.mode == 'edit')){
        password_add.setCustomValidity("");
      }
      else {
        password_add.setCustomValidity(
          (await this.validatePassword()).toString()
        );
      }

      console.log("SHIBA", password_add);
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return;
      }

      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);
      var password_key =null;
      if (this.user.password != undefined && this.user.password != null && this.user.password != "") {
        //this.user.password = this.$commonFuction.hashSHA256(this.user.password);
        password_key = this.$commonFuction.hashSHA256(this.user.password);
      }
      console.log("HASHASHASDHASHAS", this.user.password);
      console.log("AAAAAAAAAAAAAAAAAA", this.mode);
      // var uid = this.$store.getters.user;
      var params = {
        username: this.user.username,
        fullname: this.user.fullname,
        email: this.user.email,
        phone: this.user.phone,
        list_company_id: this.user.company,
        department_id: this.user.department_id,
        password: password_key,
        active: this.user.active, 
      };
      if (this.mode == 'edit'){
        params.id = this.model.id;
      }
      console.log("saveData", params);
      this.$emit("save", params);
      // }
    },
  },
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}
.required:after {
  content: " *";
  color: red;
}

.disabled {
  background-color: #eeeeee;
}
.vs__dropdown-option.vs__dropdown-option--selected,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #c40380 !important;
  color: #ffffff;
}
.p-checkbox {
  margin-right: 1rem !important;
}
</style>
