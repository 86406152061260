<template>
  <!-- <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
          <div class="p-col-8 txt-right">
          </div>
        </div>
        <div class="note">(*) Nội dung không được bỏ trống</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"
        />
      </div>
    </div>
  </div> -->
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--<div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import Config from '../../../config/index'
import DataServices from "@/core/DataServices";
import formEdit from "./add";
import gql from "graphql-tag";
import ApiRepository from "@/core/ApiRepository";

export default {
  components: {
    formEdit,
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Thêm Mới User",
      mode: "add",
      models: {},
      componentKey: 0,
      accountID: "",
      page_transaction: null,
      active_check: true,
      users_clubs_id: "",
      triggerSave: false,
    };
  },
  async mounted() {
    console.log("this.$route.name:", this.$route.query.page_transaction);
    this.mode = this.$route.name === "editUser" ? "edit" : this.mode;
    this.title = this.mode === "edit" ? "Chỉnh sửa user" : this.title;
    if (this.$route.params.id !== undefined)
      await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_USER
    );
    if (this.mode == "edit") {
      console.log("arr_role_fun_user1",this.$constants.ROLE.EDIT)
      console.log("arr_role_fun_user",arr_role.indexOf(this.$constants.ROLE.EDIT))
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    } else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: "/admin/list-users" });
    },
    UpdateActive(key) {
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if (key == "lock") {
        this.active_check = false;
      } else {
        this.active_check = true;
      }

      let variables = {
        data_update: {
          active: this.active_check,
        },
        where_key: {
          id: { _eq: this.$route.params.id },
        },
      };
      var that = this;
      this.$apollo
        .mutate({
          mutation: DataServices.updateData("users"),
          variables: variables,
        })
        .then(async (res) => {
          console.log("Success", res);
          if (key == "lock") {
            var query_t = `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`;
            await that.$apollo.mutate({
              mutation: gql(query_t),
            });
          }
          that.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Save data successfully",
            life: 3000,
          });
        });
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    // async saveData(params) {
    //   // console.log("params",params)
    //   let user_objects = {
    //     name: params.name,
    //     email: params.email,
    //     phone: params.phone,
    //     company: params.company,
    //     department: params.department,
    //   };
    //   var imgDelete = params.imgDelete;
    //   var icon_service = params.icon_service;
    //   this.triggerSave = false;
    //   if (this.mode == "add") {
    //     console.log("ADD");
    //     await this.$apollo
    //       .mutate({
    //         mutation: DataServices.insertData("users"),
    //         variables: {
    //           objects: user_objects,
    //         },
    //       })
    //       .then(async (res) => {
    //         // console.log("res",res);
    //         if (res.data[Object.keys(res.data)].affected_rows) {
    //           var returning = res.data[Object.keys(res.data)].returning[0];
    //           if (imgDelete) {
    //             await this.$CoreService
    //               .deleteDataImageMulti(
    //                 this.$constants.TYPE_IMAGE.USER_GALLERY,
    //                 imgDelete
    //               )
    //               .then(() => {});
    //           }

    //           if (icon_service && icon_service.length > 0) {
    //             for (let i = 0; i < icon_service.length; i++) {
    //               if (icon_service[i].type == "old_edit") {
    //                 icon_service.splice(i, 1);
    //               }
    //             }
    //             // var type_image = icon_service[0].type;
    //             // if (type_image != 'old_edit') {
    //             // await this.$CoreService.deleteDataImage(this.$constants.TYPE_IMAGE.USER_GALLERY, returning.id).then(async (resDelete) => {
    //             //   console.log("resresres:", resDelete);
    //             await this.$CoreService
    //               .uploadImages(this.$constants.FOLDER_S3.USER, icon_service)
    //               .then((resImage) => {
    //                 console.log("resresres=====:", resImage);
    //                 if (resImage) {
    //                   for (let i = 0; i < resImage.length; i++) {
    //                     // var objectImage = resImage[0];//lấy 1 tấm hình
    //                     this.$CoreService
    //                       .updateDataImage(
    //                         this.$constants.TYPE_IMAGE.USER_GALLERY,
    //                         returning.id,
    //                         resImage[i]
    //                       )
    //                       .then(() => {})
    //                       .catch(async (error) => {
    //                         console.log("error", error);
    //                         this.$toast.add({
    //                           severity: "error",
    //                           summary:
    //                             this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                           detail:
    //                             this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                           life: 3000,
    //                         });
    //                         this.$commonFuction.is_loading(false);
    //                       });
    //                   }
    //                 }
    //               });
    //             // });
    //             // }
    //           }
    //           if (params.password !== null && params.password !== "") {
    //             var accountsUpd = {
    //               uid: returning.id,
    //               password: this.$commonFuction.hashPassword(params.password),
    //               account: params.email,
    //               type: params.type,
    //               role: "user",
    //             };
    //             let variables = {
    //               objects: accountsUpd,
    //             };
    //             await this.$apollo
    //               .mutate({
    //                 mutation: DataServices.insertData("accounts"),
    //                 variables: variables,
    //               })
    //               .then(() => {
    //                 this.redirectEdit();
    //               })
    //               .catch(async (error) => {
    //                 console.log("error", error);
    //                 this.$toast.add({
    //                   severity: "error",
    //                   summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                   detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                   life: 3000,
    //                 });
    //                 this.$commonFuction.is_loading(false);
    //               });
    //           } else {
    //             this.redirectEdit();
    //           }
    //         }
    //       });
    //   } else {
    //     // var user_id = this.$route.params.id;
    //     let variables = {
    //       data_update: objects,
    //       where_key: {
    //         id: { _eq: this.models.id },
    //       },
    //     };
    //     await this.$apollo
    //       .mutate({
    //         mutation: DataServices.updateData("users"),
    //         variables: variables,
    //       })
    //       .then(async (res) => {
    //         // console.log("res",res);
    //         if (res.data[Object.keys(res.data)].affected_rows) {
    //           var returning = res.data[Object.keys(res.data)].returning[0];
    //           if (imgDelete) {
    //             console.log(imgDelete);
    //             await this.$CoreService
    //               .deleteDataImageMulti(
    //                 this.$constants.TYPE_IMAGE.USER_GALLERY,
    //                 imgDelete
    //               )
    //               .then(() => {});
    //           }
    //           if (icon_service && icon_service.length > 0) {
    //             // var type_image = icon_service[0].type;
    //             for (let i = 0; i < icon_service.length; i++) {
    //               if (icon_service[i].type == "old_edit") {
    //                 icon_service.splice(i, 1);
    //               }
    //             }
    //             // if (type_image != 'old_edit') {
    //             // await this.$CoreService.deleteDataImage(this.$constants.TYPE_IMAGE.USER_GALLERY, returning.id).then(async (resDelete) => {
    //             //   console.log("resresres:", resDelete);
    //             await this.$CoreService
    //               .uploadImages(this.$constants.FOLDER_S3.USER, icon_service)
    //               .then((resImage) => {
    //                 console.log("resresres=====:", resImage);
    //                 if (resImage) {
    //                   for (let i = 0; i < resImage.length; i++) {
    //                     // var objectImage = resImage[0];//lấy 1 tấm hình
    //                     this.$CoreService
    //                       .updateDataImage(
    //                         this.$constants.TYPE_IMAGE.USER_GALLERY,
    //                         returning.id,
    //                         resImage[i]
    //                       )
    //                       .then(() => {})
    //                       .catch(async (error) => {
    //                         console.log("error", error);
    //                         this.$toast.add({
    //                           severity: "error",
    //                           summary:
    //                             this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                           detail:
    //                             this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                           life: 3000,
    //                         });
    //                         this.$commonFuction.is_loading(false);
    //                       });
    //                   }
    //                 }
    //               });
    //             // });
    //             // }
    //           }
    //           var accountsUpd;
    //           if (params.password !== null && params.password !== "") {
    //             accountsUpd = {
    //               password: this.$commonFuction.hashPassword(params.password),
    //               account: params.email,
    //             };
    //           } else {
    //             accountsUpd = {
    //               account: params.email,
    //             };
    //           }
    //           let variables = {
    //             data_update: accountsUpd,
    //             where_key: {
    //               uid: { _eq: returning.id },
    //             },
    //           };
    //           await this.$apollo
    //             .mutate({
    //               mutation: DataServices.updateData("accounts"),
    //               variables: variables,
    //             })
    //             .then(() => {
    //               this.redirectEdit();
    //             })
    //             .catch(async (error) => {
    //               console.log("error", error);
    //               this.$toast.add({
    //                 severity: "error",
    //                 summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                 detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                 life: 3000,
    //               });
    //               this.$commonFuction.is_loading(false);
    //             });
    //         }
    //       });
    //   }
    //   this.$commonFuction.is_loading(false);
    // },
    async saveData(params) {
      try{
        var dataRequested = params;
        const headers= {
          "Authorization": "Bearer " + (await this.$AuthenticationApp.getToken()),
        };
        var list_action_detail = await ApiRepository.post('/_api/users/save_user', dataRequested, {headers});
        console.log("list_action_detail", list_action_detail);
        this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 });
        window.location.href = '/admin/list-users';
      }
      catch (err) {
        const error = err.response.data;
        if (error.message == "ACCOUNT_IS_EXISTED_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Lỗi', detail: 'Tài khoản đã tồn tại', life: 3000 });
        }
        else if (error.message == "EMAIL_IS_EXISTED_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Lỗi', detail: 'Email đã tồn tại', life: 3000 });
        }
        else if (error.message == "MISMATCH_PARAMS_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Lỗi', detail: 'Thiếu trường bắt buộc', life: 3000});
        }
        else if (error.message == "SERVER_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Lỗi', detail: 'Lỗi ngoại lệ', life: 3000});
        }
        else this.$toast.add({severity: 'error', summary: 'Lỗi', detail: 'Lưu dữ liệu thất bại', life: 3000});
        this.$commonFuction.is_loading(false);
        this.componentKey++;

      }
    //   if (params !== undefined && Object.entries(params).length > 0) {
    //     var user_objects = {
    //       username: params.username,
    //       fullname: params.fullname,
    //       email: params.email,
    //       phone: params.phone,
    //       list_company_id: params.list_company_id,
    //       department_id: params.department_id,
    //       password: params.password,
    //       active: params.active,
    //       // updated_at: moment(new Date()).tz("Asia/Ho_Chi_Minh").toDate(),
    //     };
        
    //     var that = this;
    //     if (this.mode === "add") {
    //       var dataInsert = this.$commonFuction.convertJsonObject(user_objects);
    //       await that.$apollo
    //         .mutate({
    //           mutation: DataServices.insertData("users"),
    //           variables: {
    //             objects: dataInsert,
    //           },
    //         })
    //         .then(async (res) => {
    //           var returning = res.data[Object.keys(res.data)].returning[0];
    //           params.company.length ? params.company.length : [];
    //           for (var i = 0; i < params.company.length; i++) {
    //             var company = this.$commonFuction.convertJsonObject(
    //               objectCompany.company[i]
    //             );
    //             var users_company = {
    //               company: company,
    //               channel_id: returning.id,
    //             };
    //             console.log("them vao", users_company);
    //             console.log("data insert", company);
    //             await that.$apollo.mutate({
    //               mutation: DataServices.insertData("users_company"),
    //               variables: {
    //                 objects: users_company,
    //               },
    //             });
    //           }
    //           console.log("Thành công");
    //           console.log(res); //
    //           this.$commonFuction.is_loading(false);
    //           that.$toast.add({
    //             severity: "success",
    //             summary: "Thông báo",
    //             detail: "Thêm thành công",
    //             life: 3000,
    //           });

    //           // this.$router.push({ path: "/admin/list-users" });
    //         })
    //         .catch(async (error) => {
    //           console.log("error", error);
    //           that.$toast.add({
    //             severity: "error",
    //             summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //             detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //             life: 3000,
    //           });
    //           this.$commonFuction.is_loading(false);
    //           // this.summitEvent = false;
    //         });
    //       var dataInsertAccount =
    //         this.$commonFuction.convertJsonObject(account_objects);

    //       await that.$apollo
    //         .mutate({
    //           mutation: DataServices.insertData("accounts"),
    //           variables: {
    //             objects: dataInsertAccount,
    //           },
    //         })
    //         .then((res) => {
    //           console.log("Thành công");
    //           console.log(res); //
    //           this.$commonFuction.is_loading(false);
    //           that.$toast.add({
    //             severity: "success",
    //             summary: "Thông báo",
    //             detail: "Thêm thành công",
    //             life: 3000,
    //           });
    //           this.$router.push({ path: "/admin/list-users" });
    //         })
    //         .catch(async (error) => {
    //           console.log("error", error);
    //           that.$toast.add({
    //             severity: "error",
    //             summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //             detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //             life: 3000,
    //           });
    //           this.$commonFuction.is_loading(false);
    //           // this.summitEvent = false;
    //         });
    //     } else {
    //       // var user_id = this.$route.params.id;
    //       let variables = {
    //         data_update: user_objects,
    //         where_key: {
    //           id: { _eq: this.models.id },
    //         },
    //       };
    //       await this.$apollo
    //         .mutate({
    //           mutation: DataServices.updateData("users"),
    //           variables: variables,
    //         })
    //         .then(async (res) => {
    //           console.log("Thành công");
    //           console.log(res); //
    //           this.$commonFuction.is_loading(false);
    //           that.$toast.add({
    //             severity: "success",
    //             summary: "Thông báo",
    //             detail: "Thêm thành công",
    //             life: 3000,
    //           });
    //           this.$router.push({ path: "/admin/list-users" });
    //         })
    //         .catch(async (error) => {
    //           console.log("error", error);
    //           that.$toast.add({
    //             severity: "error",
    //             summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //             detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //             life: 3000,
    //           });
    //           this.$commonFuction.is_loading(false);
    //           // this.summitEvent = false;
    //         });
    //     }
    //   }
    // },
    // async redirectEdit() {
    //   var that = this;
    //   var router_back = { path: "/admin/list-users" };
    //   that.$commonFuction.is_loading(false);
    //   if (that.mode == "add") {
    //     that.$router.push(router_back);
    //   } else {
    //     if (!that.$commonFuction.isEmpty(router_back)) {
    //       // that.page_transaction = ""+that.page_transaction
    //       router_back.query = {
    //         page_transaction: that.page_transaction.toString(),
    //       };
    //     }
    //     that.$router.push(router_back);
    //   }
    //   that.$toast.add({
    //     severity: "success",
    //     summary: "Success",
    //     detail: "Save data successfully",
    //     life: 3000,
    //   });
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList("users"),
        variables: {
          where_key: {
            id: { _eq: id },
            deleted: { _eq: false },
          },
        },
        fetchPolicy: "network-only",
      });
      console.log("datadata:", data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      // console.log(
      //   "SHIBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",
      //   this.models.name
      // );
      this.componentKey++;
    },
  },
  apollo: {},
};
</script>

<style lang="scss">
.manager-title-custom {
  color: #1d3118;
  font-size: 18px;
  /* margin-bottom: 16px; */
  padding-bottom: 10px;
  /* border-bottom: 1px solid #EEEEEE; */
  font-weight: 700;
}
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only {
      padding: 0 !important;
    }
  }
}
.note {
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 24px;
  padding-top: 10px;
}
.p-formgrid {
  margin: 0;
  .p-panel-header {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    background: #eeeeee;
  }
  .p-card-field {
    padding: 24px;
    .p-field {
      .p-col-fixed {
        width: 300px;
        display: inline-block !important;
        margin: 0;
      }
      .p-col-width {
        width: 500px !important;
        border-radius: 5px;
        &.disabled {
          background: #eeeeee;
          border: 0 none;
          padding: 8px 10px;
        }
        .file-select {
          margin: 0;
          input[type="file"] {
            display: none;
          }
          .select-button {
            display: inline-block;
            cursor: pointer;
            background: transparent;
            border-radius: 5px;
            color: #000000;
            font-size: 13px;
            padding: 3px 16px;
            border: 1px solid #b4b4b4;
          }
        }
        &.pac-target-input {
          height: 80px;
          word-break: break-all;
          overflow-wrap: break-word;
          resize: none;
        }
      }
      .box-images {
        margin: 0;
        margin-top: 10px;
        position: relative;
        .item-img {
          width: 150px;
          height: 150px;
          margin-right: 8px;
          margin-bottom: 8px;
          display: inline-flex;
          position: relative;
          img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            object-fit: contain;
            padding: 5px;
          }
          .button-clear {
            border-radius: 10px;
            width: 24px;
            height: 24px;
            background: #f5564e;
            float: right;
            cursor: pointer;
            position: absolute;
            right: 0px;
            img {
              vertical-align: top;
            }
          }
        }
      }
      .p-invalid {
        display: block;
      }
    }
  }
  .fixed-bottom-toolbar {
    background: transparent;
    border: 0 none;
    margin-top: 22px;
    border-top: 1px solid #b4b4b4;
    padding: 0;
    padding-top: 30px;
    .p-button {
      width: auto;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.manager-title-row {
  border-bottom: 1px solid #eeeeee;
}

.p-button-warning.p-button {
  margin: 0 10px;
}
</style>
